body, html {
  background: #000;
  height: 100%;
  font-family: "proxima-nova", sans-serif;
  color: #fff;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

*:focus {
  outline: none;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  margin-top: -8px;
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glass {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 99;
}

#nav {
  position: fixed;
  font-size: 10px;
  top: 25px;
  right: 40px;
  z-index: 101;
}

#nav a {
  display: inline-block;
  margin-right: 20px;
  font-weight: 700;
}

#about {
  padding: 60px 40px 100px;
}

#about h1 {
  font-size: 44px;
  font-weight: 900;
  margin: 0 0 50px;
  text-transform: uppercase;
  text-align: center;
}

#about h2 {
  font-size: 31px;
  line-height: 1.05em;
  width: 60%;
  margin: 0 auto;
}

#about h2 a {
  text-decoration: underline;
}

#about h2 a:hover {
  text-decoration: none;
}

#about h3, #about ul {
  width: 65%;
  margin: 0 auto;
}

#about h3 {
  margin: 70px auto 20px;
  font-size: 19px;
  font-weight: 400;
}

#about ul {
  font-size: 10px;
}

#about ul li {
  line-height: 1.4;
}

#about figure {
  padding: 0;
  margin: 0;
}

#about figure img {
  width: 55px;
}

#family {
  position: relative;
  padding-bottom: 50px;
}

#family figure {
  display: none;
}

#family .content {
  position: relative;
  z-index: 100;
  padding: 40px 0;
  width: 92%;
  margin: 0 auto;
}

#family .video {
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  margin-top: 115px;
}

#family h2 {
  font-size: 46px;
  font-weight: 700;
  margin: 0 auto;
  width: 188px;
  line-height: 1;
}

#family h3 {
  font-weight: 400;
  font-size: 16px;
  margin: 1.5em 0 .5em;
}

#family p {
  font-size: 25px;
  font-weight: 700;
  margin: 0 0 .5em;
  line-height: 0.8em;
}

#family p span {
  font-size: 7px;
}

#contact {
  width: 75%;
  margin: auto;
  padding-top: 40px;
}

#contact h2 {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 35px;
}

#contact article {
  font-size: 10px;
  width: 180px;
  padding-left: 10px;
  display: inline-block;
}

#contact article p {
  line-height: 1.5;
}

#contact article a {
  font-weight: bold;
}

#contact .button a {
  margin: 130px auto 0;
  width: 220px;
  font-size: 10px;
  text-align: center;
  line-height: 55px;
  border: 1px solid #fff;
  display: block;
  background: #000;
  transition: all 0.5s ease;
}

#contact .button a:hover {
  background: #c9c9c9;
  color: #000;
  text-decoration: none;
}

#ft {
  max-width: 600px;
  margin: 120px auto 0;
}

#ft img {
  width: 100%;
  display: block;
}

.mobile-hero {
  display: none;
}

/**
Mobile
 */
@media screen and (max-width: 520px) {
  #video1 {
    display: none;
  }
  .mobile-hero {
    display: block;
    margin: 0;
    padding: 0;
  }
  .mobile-hero img {
    width: 100%;
  }
  #nav {
    width: 100%;
    position: relative;
    height: 0;
    padding-left: 50px;
    text-align: center;
  }
  #about {
    padding: 60px 20px;
  }
  #about figure {
    margin-top: -40px;
  }
  #about h2 {
    width: 100%;
    font-size: 22px;
  }
  #about h1 {
    font-size: 33px;
  }
  #about h3, #about ul {
    width: 100%;
  }
  #contact {
    width: 100%;
  }
  #contact h2 {
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 35px 20px;
  }
  #family figure {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 115px;
    left: 0;
  }
  #family figure img {
    width: 100%;
  }
  #family iframe {
    display: none;
  }
}
