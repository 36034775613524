body, html {
  background: #000;
  height: 100%;
  font-family: "proxima-nova", sans-serif;
  color: #fff;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

a {
  color: #fff;
  text-decoration: none;
  &:hover {
	text-decoration: underline;
  }
}

*:focus {
  outline: none;
}

.video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  margin-top: -8px;
  height: 0;
  iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
}

.glass {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 99;
}

#nav {
  position: fixed;
  font-size: 10px;
  top: 25px;
  right: 40px;
  z-index: 101;
  a {
	display: inline-block;
	margin-right: 20px;
	font-weight: 700;
  }
}

#about {
  padding: 60px 40px 100px;
  h1 {
	font-size: 44px;
	font-weight: 900;
	margin: 0 0 50px;
	text-transform: uppercase;
	text-align: center;

  }
  h2 {
	font-size: 31px;
	line-height: 1.05em;
	width: 60%;
	margin: 0 auto;
	a {
	  text-decoration: underline;
	  &:hover {
		text-decoration: none;
	  }
	}
  }
  h3, ul {
	width: 65%;
	margin: 0 auto;
  }
  h3 {
	margin: 70px auto 20px;
	font-size: 19px;
	font-weight: 400;
  }
  ul {
	font-size: 10px;
	li {
	  line-height: 1.4;
	}
  }
  figure {
	padding: 0;
	margin: 0;
	img {
	  width: 55px;
	}
  }
}

#family {
  position: relative;
  padding-bottom: 50px;
  figure {
	display: none;
  }
  .content {
	position: relative;
	z-index: 100;
	padding: 40px 0;
	width: 92%;
	margin: 0 auto;
  }
  .video {
	position: absolute;
	z-index: 6;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	margin-top: 115px;
  }
  h2 {
	font-size: 46px;
	font-weight: 700;
	margin: 0 auto;
	width: 188px;
	line-height: 1;
  }
  h3 {
	font-weight: 400;
	font-size: 16px;
	margin: 1.5em 0 .5em;
  }
  p {
	font-size: 25px;
	font-weight: 700;
	margin: 0 0 .5em;
	line-height: 0.8em;
	span {
	  font-size: 7px;
	}
  }
}

#contact {
  width: 75%;
  margin: auto;
  padding-top: 40px;
  h2 {
	font-size: 15px;
	font-weight: 400;
	margin: 0 0 35px;
  }
  article {
	font-size: 10px;
	width: 180px;
	padding-left: 10px;
	display: inline-block;
	p {
	  line-height: 1.5;
	}
	a {
	  font-weight: bold;
	}
  }
  .button {
	a {
	  margin: 130px auto 0;
	  width: 220px;
	  font-size: 10px;
	  text-align: center;
	  line-height: 55px;
	  border: 1px solid #fff;
	  display: block;
	  background: #000;
	  transition: all 0.5s ease;
	  &:hover {
		background: #c9c9c9;
		color: #000;
		text-decoration: none;
	  }
	}

  }
}

#ft {
  max-width: 600px;
  margin: 120px auto 0;
  img {
	width: 100%;
	display: block;
  }
}
.mobile-hero {
  display: none;
}

/**
Mobile
 */
@media screen and (max-width: 520px) {
  #video1 {
	display: none;
  }
  .mobile-hero {
	display: block;
	margin: 0;
	padding: 0;
	img {
	  width: 100%;
	}
  }
  #nav {
	width: 100%;
	position: relative;
	height: 0;
	padding-left: 50px;
	text-align: center;
  }
  #about {
	padding: 60px 20px;
  }
  #about figure {
	margin-top: -40px;
  }
  #about h2 {
	width: 100%;
	font-size: 22px;
  }
  #about h1 {
	font-size: 33px;
  }
  #about h3, #about ul {
	width: 100%;

  }
  #contact {
	width: 100%;
  }
  #contact h2 {
	font-size: 15px;
	font-weight: 400;
	margin: 0 0 35px 20px;
  }
  #family {
	figure {
	  display: block;
	  margin: 0;
	  padding: 0;
	  position: absolute;
	  top: 115px;
	  left: 0;
	  img {
		width: 100%;

	  }
	}
	iframe {
	  display: none;
	}
  }

}